jQuery.noConflict();
(function ($) {
	/*
	-------------------------------------
	FUNCTIONS
	-------------------------------------
	*/

	$(window).load(function () {
		/* match height */
		$('.card-info').matchHeight();
		$('.news-post').matchHeight();
	});

	/*
	-------------------------------------
	DOCUMENT READY
	-------------------------------------
	*/

	$(document).ready(function () {
		$('footer .email input')
			.focus(function () {
				$(this).attr('placeholder', 'Enter your email address...');
				$(this).addClass('selected');
			})
			.blur(function () {
				$(this).attr('placeholder', 'Sign Up for Email Updates');
				$(this).removeClass('selected');
			});

		if ($('body').hasClass('blog')) {
			$('.article-title').text('News');
		}
		$('form#search-form img.search').click(function () {
			$(this).closest('form#search-form').submit();
		});

		/* match height */
		$('.card-info').matchHeight();
		$('.news-post').matchHeight();
		$('.stats').matchHeight();

		/* hamburger */
		var mobileStatus = false;

		$('#hamburger').click(function () {
			$(this).toggleClass('open');
			$('#mobile-nav').toggleClass('open');
			$('#mobile-nav .pages').toggleClass('open');
			$('header').toggleClass('open');
			$('body').toggleClass('v-body__nav--open');

			// no scroll while mobile menu open
			if ($('#mobile-nav').hasClass('.open')) {
				$('body').classList.add('v-body__nav--open');
			} else {
				$('body').classList.remove('v-body__nav--open');
			}

			if ($(window).width() <= 500) {
				$('body').toggleClass('menu-open');

				if (mobileStatus === false) {
					document.ontouchmove = function (e) {
						// disable mobile scrolling
						e.preventDefault();
					};
					mobileStatus = true;
				} else {
					document.ontouchmove = function (e) {
						// enable mobile scrolling
						return true;
					};
					mobileStatus = false;
				}
			}
		});

		// Handle subnav
    $('.subnav .trigger').click(function() {
			if (window.innerWidth < 768) {
					$(this).toggleClass('active');
					$('.subnav .trigger .view-hide').html($('.subnav .trigger .view-hide').html() === 'Hide' ? 'View' : 'Hide');
					$('.subnav').toggleClass('active');
					$('.subnav ul').slideToggle(250);
					$('.subnav ul.children').css('display', 'none');
			}
		});

		// Listen for window resize events for subnav
		$(window).resize(function() {
				if (window.innerWidth >= 768) {
						// Remove inline styles that may have been applied below 768px
						$('.subnav ul').removeAttr('style');
						$('.subnav ul.children').removeAttr('style');
				}
		});

		/* locations in footer */
		$('.locations').click(function () {
			$('.addresses-mobile').slideToggle();
			$('html, body').animate({ scrollTop: $('.locations').offset().top }, 250);
		});

		/* mobile buttons */
		$('#mobile-nav .pages ul li a').on('touchstart', function () {
			$(this).css('border-bottom', '1px #ffffff solid');
			$(this).css('transition', '0s all');
		});

		$('#mobile-nav .pages ul li a').on('touchend', function () {
			$(this).css('border-bottom', '1px #6eb2c8 solid');
			$(this).css('transition', '.25s all');
		});

		$('a.btn').on('touchstart', function () {
			$(this).css('background', 'transparent');
			$(this).css('color', '#181d20');
			$(this).css('transition', '0s all');
		});

		$('a.btn').on('touchend', function () {
			$(this).css('background', '#6eb2c8');
			$(this).css('color', '#ffffff');
			$(this).css('transition', '.25s all');
		});

		$('a.btn.black').on('touchstart', function () {
			$(this).css('background', '#6eb2c8');
			$(this).css('transition', '0s all');
		});

		$('a.btn.black').on('touchend', function () {
			$(this).css('background', '#171c1e');
			$(this).css('transition', '.25s all');
		});

		$('.box').on('touchstart', function () {
			if ($(window).width() <= 500) {
				$(this).css('background', 'transparent');
				$(this).css('color', '#181d20');
				$(this).css('transition', '0s all');
			} else {
				$(this).css('background', '#6eb2c8');
				$('.box span').css('color', '#171c1e');
				$(this).css('transition', '0s all');
				$('.box span').css('transition', '0s all');
			}
		});

		$('.box').on('touchend', function () {
			if ($(window).width() <= 500) {
				$(this).css('background', '#6eb2c8');
				$(this).css('color', '#ffffff');
				$(this).css('transition', '0s all');
			} else {
				$(this).css('background', '#181d20');
				$('.box span').css('color', '#6eb2c8');
				$(this).css('transition', '.25s all');
				$('.box span').css('transition', '.25s all');
			}
		});

		$('#services .card').on('touchstart', function () {
			$(this).find('.card-info').css('background', '#6eb2c8');
			$(this).find('.card-info span').css('color', '#181d20');
			$(this).find('.card-info').css('transition', '0s all');
			$(this).find('.card-info span').css('transition', '0s all');
		});

		$('#services .card').on('touchend', function () {
			$(this).find('.card-info').css('background', '#181d20');
			$(this).find('.card-info span').css('color', '#6eb2c8');
			$(this).find('.card-info').css('transition', '.25s all');
			$(this).find('.card-info span').css('transition', '.25s all');
		});

		$('#cards .card .card-info a.btn').on('touchstart', function () {
			$(this).css('background', 'transparent');
			$(this).css('color', '#ffffff');
			$(this).css('transition', '0s all');
		});

		$('#cards .card .card-info a.btn').on('touchend', function () {
			$(this).css('background', '#6eb2c8');
			$(this).css('color', '#ffffff');
			$(this).css('transition', '.25s all');
		});

		$('.klnb-left, .klnb-right').on('touchstart', function () {
			$(this).css('color', '#0fd9bf');
			$(this).css('transition', '0s all');
		});

		$('.klnb-left, .klnb-right').on('touchend', function () {
			$(this).css('color', '#5d5d5d');
			$(this).css('transition', '.25s all');
		});
	});
})(jQuery);
